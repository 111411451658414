import {combineReducers} from 'redux'

import componentsReducer from './componentsReducer'
import selectionReducer from './selectionReducer'
import userReducer from './userReducer'
import uiReducer from './uiReducer'
import dateReducer from './dateReducer'
import gameReducer from './gameReducer'
import lineupsReducer from './lineupsReducer'
import propertiesReducer from './propertiesReducer'


const rootReducer = combineReducers({
    components: componentsReducer,
    selection: selectionReducer,
    user: userReducer,
    ui: uiReducer,
    date: dateReducer,
    game: gameReducer,
    lineups: lineupsReducer,
    properties: propertiesReducer
});

export default rootReducer
