export const SET_COMPONENTS_ARR = 'SET_COMPONENTS_ARR';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CUR_CUSTOMER = 'SET_CUR_CUSTOMER';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_TOPICS_ARRAY = 'SET_TOPICS_ARRAY';
export const LOGIN = 'USER_LOGIN';
export const LOGOUT = 'USER_LOGOUT';
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const SET_NEW_DATE = 'SET_NEW_DATE';
export const RESET_DATE = 'RESET_DATE';
export const SET_GAME = 'SET_GAME';
export const SET_LINEUPS = 'SET_LINEUPS';
export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';
export const RESET_PROPERTIES = 'RESET_PROPERTIES';


export const setComponentsArr = componentsArray => ({
    type: SET_COMPONENTS_ARR,
    payload: componentsArray
});

export const setCurLanguage = language => ({
    type: SET_LANGUAGE,
    payload: language
});

export const setCurCustomer = customer => ({
    type: SET_CUR_CUSTOMER,
    payload: customer
});

export const login = (user) => ({
    type: LOGIN,
    payload: user
});

export const logout = () => ({
    type: LOGOUT,
});

export const showSnackbar = message => {
    return dispatch => {
        dispatch({type: SNACKBAR_SUCCESS, message});
    };
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({type: SNACKBAR_CLEAR});
    };
};

export const setNewDate = targetDateUnix => ({
    type: SET_NEW_DATE,
    payload: targetDateUnix
});

export const resetDate = {
    type: RESET_DATE,
};

export const setGame = (gameData) => ({
    type: SET_GAME,
    payload: {
        gameId: gameData.gameId,
        homeTeam: gameData.homeTeam,
        awayTeam: gameData.awayTeam,
        gameType: gameData.gameType,
        gameDate: gameData.gameDate
    }
});

export const setLineups = (lineups) => ({
    type: SET_LINEUPS,
    homePlayersObj: lineups.homePlayersObj,
    awayPlayersObj: lineups.awayPlayersObj
});

export const updateProperties = (propertyObj) => ({
    type: UPDATE_PROPERTIES,
    property: propertyObj.property,
    propertyValue: propertyObj.propertyValue
});

export const resetProperties = () => ({
    type: RESET_PROPERTIES
});

export const setTopic = (topic) => ({
    type: SET_TOPIC,
    payload: topic
});

export const setTopicsArr = (topicsArr) => ({
    type: SET_TOPICS_ARRAY,
    payload: topicsArr
});
