import React, {Component} from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoginForm from './components/LoginForm'
import LoginPanel from './components/LoginPanel'

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";


const styles = {
    Login: {
        padding: '120px 0',
    },
    card: {
        maxWidth: '660px',
        margin: 'auto',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0',
        '&:last-child': {
            padding: '0',
        }
    },
};

class Login extends Component {
    render() {
        const {classes, userHasAuthenticated, history} = this.props;

        return (
            <div className={classes.Login}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <LoginForm userHasAuthenticated={userHasAuthenticated} history={history}/>
                        <LoginPanel/>
                    </CardContent>
                </Card>
            </div>


        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
