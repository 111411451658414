import {LOGIN, LOGOUT} from '../actions'

const initialState = {
    user: {},
    userPhoto: null,
    userGroups: [],
    isAuthenticated: false,
    isAuthenticating: false,
    admin: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload.user,
                userPhoto: action.payload.userPhoto,
                userGroups: action.payload.userGroups,
                isAuthenticated: action.payload.isAuthenticated,
                isAuthenticating: action.payload.isAuthenticating,
                admin: action.payload.admin
            };
        case LOGOUT:
            return {
                ...state, user: {}, userPhoto: null, userGroups: [], isAuthenticated: false, isAuthenticating: false, admin: false
            };
        default:
            return state;
    }
};

export default userReducer
