import React from 'react';

import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        textAlign: 'center'
    }
});


export default function Forbidden() {
    const classes = useStyles();

    return (
        <article className={classes.root}>
            <h1>Foul!</h1>
            <div>
                <p>Sorry for the inconvenience, but your account is not allowed to view this page</p>
                <p>&mdash; hoopsAI Team</p>
            </div>
        </article>
    );
}
