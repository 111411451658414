import React, {useState} from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import placeholder_img from "../../resources/placeholder_img.jpg";
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";
import PopperMenu from '../../components/PopperMenu'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        marginRight: theme.spacing(12),
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        cursor: 'pointer',
        boxShadow: '0px 08px 21px rgba(0, 0, 0, 0.35);'
    }
}));


export default function UserMenu(props) {
    const classes = useStyles();

    const user = useSelector(state => state.user.user);
    const userPhoto = useSelector(state => state.user.userPhoto);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div className={classes.root}>
            <Avatar alt="userName" src={userPhoto || placeholder_img} className={classes.avatar} onClick={handleClick}/>
            <Typography onClick={handleClick}
                        variant="h6"
                        color="inherit"
                        style={{color: 'white', paddingLeft: '16px', cursor: 'pointer',}}>
                {user}
            </Typography>
            <PopperMenu anchorEl={anchorEl} onClickAwayListener={() => setAnchorEl(null)}>
                <MenuItem onClick={handleClick}>Profile</MenuItem>
                <MenuItem onClick={handleClick}>My account</MenuItem>
                <MenuItem onClick={() => props.onLogout()}>Logout</MenuItem>
            </PopperMenu>
        </div>
    );
}

UserMenu.propTypes = {
    onLogout: PropTypes.func.isRequired,
};
