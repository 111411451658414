import {UPDATE_PROPERTIES, RESET_PROPERTIES} from '../actions'

const initialState = {
    teamBias: {teamId: null, biasLevel: 0},
    playersBias: [],
    targetLength: 5,
    enableImages: false,
    enableVideos: false,
    enableTwitter: false,
    enableInstagram: false,
    enableGec: false,
    language: 'en_us',
    betting: false,
    promotions: [],
    technicalLevel: '1',
    apiKey: null,
    template: null,
    docGroup: null
};

const propertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROPERTIES:
            return {
                ...state,
                [action.property]: action.propertyValue
            };
        case RESET_PROPERTIES:
            return {...state, ...initialState, apiKey: state.apiKey};
        default:
            return state;
    }
};

export default propertiesReducer
