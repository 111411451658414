import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsConfig from './tools/aws-config';
import Auth from '@aws-amplify/auth';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {store, persistor} from './store/store'
import 'react-sortable-tree/style.css';
import {PersistGate} from 'redux-persist/integration/react'
import {initGA} from "./components/Tracking";

initGA('UA-121896311-2');

Auth.configure({
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <App/>
            </Router>
        </PersistGate>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
