import {createStore, applyMiddleware} from 'redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'
// import {createLogger} from 'redux-logger'
import reducer from '../reducers'

const middleware = [thunk];

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['user', 'ui', 'date', 'game', 'lineups']
};

// middleware.push(createLogger())

const persistedReducer = persistReducer(persistConfig, reducer);

const configureStore = () => {
    const store = createStore(
        persistedReducer,
        applyMiddleware(...middleware),
        // other store enhancers if any
    );
    const persistor = persistStore(store);
    return {store, persistor}
};


export default configureStore
