import React from "react";

import Typography from '@material-ui/core/Typography';
import login_bg from '../resources/login_bg.svg'

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";


const styles = {
    LoginPanel: {
        padding: '60px 0',
        width: '240px',
        backgroundImage: `url(${login_bg})`,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    button: {
        borderRadius: "40px",
    }
};

function LoginPanel(props) {
    const {classes} = props;

    return (
        <div className={classes.LoginPanel}>
            <Typography className={classes.title} variant="h4" color="inherit">
                Welcome!
            </Typography>
            <Typography className={classes.subtitle} variant="body1" color="inherit">
                Sign in to start generating content
            </Typography>
        </div>
    );
}

LoginPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPanel);
