import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {useSelector} from 'react-redux'

import {makeStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import UserMenu from "./UserMenu";
import HoopsBreadCrumbs from "./HoopsBreadCrumbs";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: 'rgba(28, 31, 69, 0.0)',
        boxShadow: 'unset',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarClosed: {
        paddingLeft: theme.spacing(10),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        fontWeight: '800',
        color: '#fff'
    },
    envTitle: {
        fontWeight: '800',
        paddingRight: theme.spacing(3),
        color: '#fff'
    },
    rightSide: {
        display: 'flex',
        alignItems: 'center',
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
    },
    toolbarContainer: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: theme.breakpoints.values.lg
    }
}));


export default function HomeAppBar(props) {
    const classes = useStyles();
    const customer = useSelector(state => state.selection.customer);
    // const topic = useSelector(state => state.selection.topic);
    const headerVariant = (props.isBigScreen) ? 'h4' : 'h5';
    const envVariant = (props.isBigScreen) ? 'h5' : 'h6';
    const minHeaderHeight = (props.isBigScreen) ? '128px' : '112';

    const envStr = process.env.REACT_APP_API_ENV === 'dev' ? 'Development' : 'Production';

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, props.open && classes.appBarShift, !props.open && classes.appBarClosed)}>
            <Toolbar style={{minHeight: minHeaderHeight}}>
                <div className={classes.toolbarContainer}>
                    <div className={classes.leftSide}>
                        <Typography component="h1" variant={headerVariant} color="inherit" noWrap className={classes.title}>
                            Customer Group - {customer}
                        </Typography>
                            <HoopsBreadCrumbs/>
                    </div>

                    <div className={classes.rightSide}>
                        <Typography variant={envVariant} noWrap className={classes.envTitle}>{envStr}</Typography>
                        <UserMenu onLogout={props.onLogout}/>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}

HomeAppBar.propTypes = {
    open: PropTypes.bool.isRequired,
    isBigScreen: PropTypes.bool,
    onLogout: PropTypes.func.isRequired
};
