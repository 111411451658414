import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import MessagesIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AdminIcon from '@material-ui/icons/Apps';
import DocumentIcon from '@material-ui/icons/Description';
import DataPointIcon from '@material-ui/icons/ControlPoint';
import ComponentIcon from '@material-ui/icons/Ballot';
import CombinationIcon from '@material-ui/icons/Extension';
import SubjectIcon from '@material-ui/icons/Subject';
import CmsIntegrationIcon from '@material-ui/icons/Toc';
import AnalyticsIcon from '@material-ui/icons/PieChart';
import TestingIcon from '@material-ui/icons/AssignmentTurnedIn';

import {withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";

const StyledListItem = withStyles(theme => ({
    root: {
        "&$selected": {
            backgroundColor: 'white',
            boxShadow: '0px 10px 20px rgba(95, 131, 165, 0.10)',
            borderLeft: `3px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main
        }
    },
    selected: {}
}))(ListItem);


const listItemsDefs = [
    {index: 0, path: 'dashboard', icon: <AmpStoriesIcon/>, text: 'Dashboard'},
    {index: 1, path: 'topics', icon: <SubjectIcon/>, text: 'Topics'},
    {index: 2, path: 'messages', icon: <MessagesIcon/>, text: 'Messages'},
    {index: 3, path: 'unverified', icon: <AssignmentIcon/>, text: 'Unverified'},
    {index: 4, path: 'doc-structures', icon: <DocumentIcon/>, text: 'Doc Structures'},
    {index: 5, path: 'doc-structure-groups', icon: <CombinationIcon/>, text: 'Doc Structure Groups'},
    {index: 6, path: 'cms-integration', icon: <CmsIntegrationIcon/>, text: 'CMS Integration'},
    {index: 10, path: 'analytics', icon: <AnalyticsIcon/>, text: 'Analytics'},
    {index: 11, path: 'testing', icon: <TestingIcon/>, text: 'Testing'}
];

const adminItemsDefs = [
    {index: 7, path: 'components', icon: <ComponentIcon/>, text: 'Components'},
    {index: 8, path: 'data-points', icon: <DataPointIcon/>, text: 'Data Points'},
    {index: 9, path: 'combinations', icon: <CombinationIcon/>, text: 'Combinations'},
    {index: 12, path: 'admin', icon: <AdminIcon/>, text: 'Admin Panel'}
];

export const mainListItems = (selectedIndex, isAdmin) => {
    let itemDefs = listItemsDefs;

    if (isAdmin) {
        itemDefs = listItemsDefs.concat(adminItemsDefs)
    }

    const listItems = itemDefs.map(itemDef => (
        <Link to={`/${itemDef.path}`} key={itemDef.index} style={{textDecoration: 'none', color: 'unset'}}>
            <StyledListItem button
                            selected={selectedIndex === itemDef.index}>
                <ListItemIcon>
                    {itemDef.icon}
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1" >{itemDef.text}</Typography>}/>
            </StyledListItem>
        </Link>
    ));

    return (
        <>
            {listItems}
        </>
    );
};
