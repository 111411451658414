import {SET_GAME} from '../actions'

const initialState = {
    gameId: null,
    homeTeam: null,
    awayTeam: null,
    gameType: null,
    gameDate: null
};


const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GAME:
            return {
                ...state,
                gameId: action.payload.gameId,
                homeTeam: action.payload.homeTeam,
                awayTeam: action.payload.awayTeam,
                gameType: action.payload.gameType,
                gameDate: action.payload.gameDate
            };
        default:
            return state;
    }
};

export default gameReducer
