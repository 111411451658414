export const LANGUAGES = {
    en_US: {name: 'English', locale: 'en_US'},
    he_IL: {name: 'Hebrew', locale: 'he_IL'},
    es_ES: {name: 'Spanish', locale: 'es_ES'},
};

export const SUPPORTED_LOCALES = [LANGUAGES.en_US.locale, LANGUAGES.he_IL.locale, LANGUAGES.es_ES.locale];

export const TOPICS = {
    finance: {topic_id: '3e147b49-2d41-44a5-8fef-e44f9caef693', topic_name: 'Finance', topic_alias: 'finance', id: null, status: 'active', group: 'finance', family: 'finance'},
    nba: {topic_id: '83529ca7-1636-490f-9465-5e655981d8a2', topic_name: 'NBA', topic_alias: 'nba', id: null, status: 'active', group: 'basketball', family: 'sport'}
};

export const ASSET_CLASSES  = {
    CURRENCIES: 'currencies',
    INDICES: 'indices',
    STOCKS: 'stocks',
    COMMODITIES: 'commodities',
    CRYPTOCURRENCY: 'cryptocurrency',
};
