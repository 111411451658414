import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return (
            <div className="NotFound">
                <header className="NotFound-header">
                    <p>
                        Page not Found
                    </p>
                </header>
            </div>
        );
    }
}

export default NotFound;
