import React, {Component} from "react";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";

import Auth from '@aws-amplify/auth';


const StyledButton = withStyles({
    root: {
        backgroundColor: '#FD854F',
        borderRadius: '40px',
        color: '#fff', // #EA6088
        marginRight: '10px',
        textTransform: 'none',
        minWidth: "120px",
        height: "40px",
    },
    label: {
        fontSize: '1rem',
        fontWeight: '600'
    },
})(Button);

const styles = {
    LoginForm: {
        width: '440px',
        padding: '80px 0',
        color: '#25313B',
        '& form': {
            margin: '0 auto',
            maxWidth: '260px',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    formControl: {
        paddingBottom: '40px',
    },
    title: {
        paddingBottom: '60px',
        textAlign: 'center'
    },
    loginBtn: {
        margin: "auto"
    }
};

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            userName: "",
            password: "",
            showChangePassword: false,
            loggedUser: null,
            showPassword: false
        };
    }

    validateForm() {
        return this.state.userName.length > 0 && this.state.password.length > 0;
    }

    handleChange = prop => event => {
        this.setState({[prop]: event.target.value});
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleChangePassword = async event => {
        event.preventDefault();

       await Auth.completeNewPassword(
            this.state.loggedUser,
            this.state.password,
        );

        const userName = this.state.loggedUser.signInUserSession.idToken.payload["cognito:username"];
        const userPhoto = this.state.loggedUser.signInUserSession.idToken.payload.picture;
        const userGroups = this.state.loggedUser.signInUserSession.accessToken.payload["cognito:groups"] || [];

        let admin = false;
        // TODO: Remove this
        const adminIndex = userGroups.findIndex(g => g === 'hoopsai-admins');

        if (adminIndex !== -1) {
            admin = true;
            userGroups.splice(adminIndex, 1);
        }

        this.props.userHasAuthenticated(true, userName, userGroups, userPhoto, admin);
        this.props.history.push("/");
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const user = await Auth.signIn(this.state.userName.toLowerCase(), this.state.password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState({showChangePassword: true, loggedUser: user});
            } else {
                const userName = user.signInUserSession.idToken.payload["cognito:username"];
                const userPhoto = user.signInUserSession.idToken.payload.picture;
                const userGroups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];

                let admin = false;
                // TODO: Remove this
                const adminIndex = userGroups.findIndex(g => g === 'hoopsai-admins');

                if (adminIndex !== -1) {
                    admin = true;
                    userGroups.splice(adminIndex, 1);
                }

                this.props.userHasAuthenticated(true, userName, userGroups, userPhoto, admin);
                this.props.history.push("/");
            }
        } catch (e) {
            alert(e.message);
            this.setState({isLoading: false});
        }
    };

    renderLogin() {
        const {classes} = this.props;
        const {userName, showPassword, password} = this.state;

        return (
            <div className={classes.LoginForm}>
                <Typography className={classes.title} variant="h4" color="inherit">
                    Sign in to hoopsAI
                </Typography>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="userName">User Name</InputLabel>
                        <Input
                            id="userName"
                            type={'text'}
                            value={userName}
                            onChange={this.handleChange('userName')}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="adornment-password">Password</InputLabel>
                        <Input
                            id="adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={this.handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword.bind(this)}
                                    >
                                        {showPassword ? <div>on</div> : <div>off</div>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <StyledButton
                        className={classes.loginBtn}
                        variant="contained"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        Login
                    </StyledButton>
                </form>
            </div>
        )
    }

    renderChangePassword() {
        const {classes} = this.props;
        const {password} = this.state;

        return (
            <div className={classes.LoginForm}>
                <Typography className={classes.title} variant="h3" color="inherit">
                    Change Password
                </Typography>
                <form onSubmit={this.handleChangePassword.bind(this)}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type='password'
                            value={password}
                            onChange={this.handleChange('password')}
                        />
                    </FormControl>
                    <StyledButton
                        className={classes.loginBtn}
                        variant="contained"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        Change Password
                    </StyledButton>
                </form>
            </div>
        )
    }

    render() {
        const {showChangePassword} = this.state;

        return showChangePassword ? this.renderChangePassword() : this.renderLogin();
    }
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
    userHasAuthenticated: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm);
