import moment from 'moment';
import 'moment-timezone';

import {
    SET_NEW_DATE, RESET_DATE
} from '../actions'

const initialState = {
    currentTimestamp: moment(new Date()).tz("America/New_York").valueOf()
};

const dateReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_NEW_DATE:
            return { ...state, currentTimestamp: action.payload};
        case RESET_DATE:
            return { ...state, currentTimestamp: initialState};
        default:
            return state;
    }
};

export default dateReducer
