import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import {makeStyles} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";

import {mainListItems} from "./listItems";
import hoopsaiIcon from '../../resources/hoopsAI_Icon_Color_Expression1.svg';
import {Link} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    logo: {
        backgroundColor: 'rgba(254, 101, 98, 0.35)',
        width: '48px',
        height: '48px',
        borderRadius: '50px',
        boxShadow: '0px 10px 20px rgba(254, 101, 98, 0.65)',
    },
    drawerIconButton: {
        color: theme.palette.primary.main,
        boxShadow: '0px 07px 12px rgba(95, 131, 165, 0.39)'
    },
    drawerTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        border: 'unset',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 220,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('lg')]: {
            width: 240,
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
}));


export default function HomeDrawer(props) {
    const classes = useStyles();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            open={props.open}
        >
            <div className={classes.drawerTop}>
                <Link to='/'>
                    <img src={hoopsaiIcon} alt={'hoopsLogo'} className={classes.logo}/>
                </Link>
            </div>
            <List>{mainListItems(props.selectedIndex, props.isAdmin)}</List>
            <div className={classes.drawerBottom}>
                <IconButton className={classes.drawerIconButton} onClick={props.handleSetOpen}>
                    {props.open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </div>
        </Drawer>
    )
}

HomeDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    handleSetOpen: PropTypes.func.isRequired,
};
