import {SET_LINEUPS} from '../actions'

const initialState = {
    homePlayers: {},
    awayPlayers: {},
};


const lineupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LINEUPS:
            return {
                ...state,
                homePlayers: action.homePlayersObj,
                awayPlayers: action.awayPlayersObj,
            };
        default:
            return state;
    }
};

export default lineupsReducer
