import {useDispatch, useSelector} from "react-redux";
import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {clearSnackbar} from "../actions";

export default function SnackBar() {
    const dispatch = useDispatch();
    const {successSnackbarMessage, successSnackbarOpen} = useSelector(state => state.ui);

    function handleClose() {
        dispatch(clearSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            open={successSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar">
                    {successSnackbarMessage}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
            ]}
        />
    );
}
