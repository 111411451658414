import {SET_LANGUAGE, SET_CUR_CUSTOMER, SET_TOPIC, SET_TOPICS_ARRAY} from '../actions'
import {LANGUAGES} from '../tools/consts'
import {TOPICS} from '../tools/consts'

const initialState = {
    language: LANGUAGES.en_US.locale,
    customer: null,
    topicId: TOPICS.finance.topic_id,
    topicsArr: [TOPICS.finance],
};


const selectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {...state, language: action.payload};
        case SET_CUR_CUSTOMER:
            return {...state, customer: action.payload};
        case SET_TOPIC:
            return {...state, topicId: action.payload};
        case SET_TOPICS_ARRAY:
            return {...state, topicsArr: action.payload};
        default:
            return state;
    }
};

export default selectionReducer
