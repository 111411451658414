import Auth from '@aws-amplify/auth';

const BASE_ENDPOINT = 'https://dtbf3vs01d.execute-api.us-east-1.amazonaws.com/prod';
// const API_ENV = process.env.REACT_APP_API_ENV;
const ENDPOINT = BASE_ENDPOINT;


function getOptions(jwt) {
    return {
        headers: new Headers({'Authorization': jwt})
    };
}


const baseGetRequest = async (apiPath, methodType, postObject) => {
    const API = `/api/v1/${apiPath}`;
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
    const options = getOptions(jwt);

    try {
        switch (methodType) {
            case 'GET':
                options.method = 'GET';
                break;
            case 'POST':
                options.method = 'POST';
                options.headers.append("Content-Type", "application/json; charset=utf-8");
                if (postObject) {
                    options.body = JSON.stringify(postObject);
                }
                break;
            case 'PUT':
                options.method = 'PUT';
                options.headers.append("Content-Type", "application/json; charset=utf-8");
                if (postObject) {
                    options.body = JSON.stringify(postObject);
                }
                break;
            case 'DELETE':
                options.method = 'DELETE';
                break;
            default:
                options.method = 'GET';
        }

        const response = await fetch(`${ENDPOINT}${API}`, options);

        if (!response.ok) {
            throw Error(response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.log(error.message);
        throw Error(error.message);
    }
};


/** Templates APIs **/

export const createTemplate = async (customer, postObject) => {
    return await baseGetRequest(`${customer}/template`, 'POST', postObject);
};


export const getTemplateById = async (customer, templateId) => {
    return await baseGetRequest(`${customer}/template/${templateId}`);
};


export const getTemplates = async (customer, component, topicId, language) => {
    return await baseGetRequest(`${customer}/template?component=${component}&language=${language}&topic_id=${topicId}`);
};


export const getUnverifiedTemplates = async (customer, topicId, language) => {
    let API = `${customer}/template?filter_unverified=true&topic_id=${topicId}`;

    if (language) {
        API += `&language=${language}`
    }
    return await baseGetRequest(API);
};


export const updateTemplate = async (customer, templateId, postObject) => {
    return await baseGetRequest(`${customer}/template/${templateId}`, 'PUT', postObject);
};


export const deleteTemplate = async (customer, templateId) => {
    return await baseGetRequest(`${customer}/template/${templateId}`, 'DELETE');
};


export const listComponents = async (customer) => {
    return await baseGetRequest(`${customer}/list-components`);
};


/** Customers APIs **/

export const newCustomer = async (customer, source_customer) => {
    return await baseGetRequest(`${customer}/new/${source_customer}`, 'POST');
};


export const updateCustomer = async (customer, source_customer) => {
    return await baseGetRequest(`${customer}/update/${source_customer}`, 'POST');
};


/** Components APIs **/

export const createComponent = async (postObject) => {
    return await baseGetRequest(`components`, 'POST', postObject);
};


export const getComponents = async (topicId) => {
    let API = `components`;

    if (topicId) {
        API += `?topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const deleteComponent = async (component_id, component_name) => {
    let API = `components`;

    if (component_id) {
        API += `?component_id=${component_id}`
    } else if (component_name) {
        API += `?component_name=${component_name}`
    }

    return await baseGetRequest(API, 'DELETE');
};


/** Data Points APIs **/

export const createDataPoint = async (postObject) => {
    return await baseGetRequest(`data-points`, 'POST', postObject);
};


export const getDataPoints = async (componentId, topicId, include_global = false) => {
    let API = `data-points`;

    if (componentId) {
        API += `?component_id=${componentId}&include_global=${include_global}&topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const getDataPointByComponentId = async (componentId, topicId) => {
    let API = `data-points/${componentId}`;

    if (topicId) {
        API += `?topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const updateDataPoint = async (postObject, target_data_point_id, target_data_point_name) => {
    let API = `data-points`;

    if (target_data_point_id) {
        API += `?target_data_point_id=${target_data_point_id}`
    } else if (target_data_point_name) {
        API += `?target_data_point_name=${target_data_point_name}`
    }

    return await baseGetRequest(API, 'PUT', postObject);
};


export const deleteDataPoint = async (data_point_id, data_point_name) => {
    let API = `data-points`;

    if (data_point_id) {
        API += `?data_point_id=${data_point_id}`
    } else if (data_point_name) {
        API += `?data_point_name=${data_point_name}`
    }

    return await baseGetRequest(API, 'DELETE');
};


/** Doc Structures APIs **/

export const createDocStructure = async (customer, postObject) => {
    return await baseGetRequest(`${customer}/doc-structures`, 'POST', postObject);
};


export const getDocStructures = async (customer, topicId) => {
    let API = `${customer}/doc-structures`;

    if (topicId) {
        API += `?topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const getDocStructure = async (customer, structure_id) => {
    let API = `${customer}/doc-structures/${structure_id}`;

    return await baseGetRequest(API, 'GET');
};


export const deleteDocStructure = async (customer, structure_id, structure_name) => {
    let API = `${customer}/doc-structures`;

    if (structure_id) {
        API += `?structure_id=${structure_id}`
    } else if (structure_name) {
        API += `?structure_name=${structure_name}`
    }

    return await baseGetRequest(API, 'DELETE');
};


export const updateDocStructure = async (customer, postObject, structure_id) => {
    let API = `${customer}/doc-structures`;

    if (structure_id) {
        API += `?structure_id=${structure_id}`
    }

    return await baseGetRequest(API, 'PUT', postObject);
};


/** Combination Restrictions APIs **/

export const createRestriction = async (postObject) => {
    return await baseGetRequest(`combination-restrictions`, 'POST', postObject);
};


export const getRestrictions = async (topicId) => {
    let API = `combination-restrictions`;

    if (topicId) {
        API += `?topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const getRestrictionsByComponentId = async (component_id) => {
    let API = `combination-restrictions/${component_id}`;

    return await baseGetRequest(API, 'GET');
};


export const deleteRestriction = async (restriction_id) => {
    let API = `combination-restrictions`;

    if (restriction_id) {
        API += `?restriction_id=${restriction_id}`
    }

    return await baseGetRequest(API, 'DELETE');
};


/** Topics APIs **/

export const createTopic = async (postObject) => {
    return await baseGetRequest(`topics`, 'POST', postObject);
};


export const getTopics = async () => {
    let API = `topics`;

    return await baseGetRequest(API, 'GET');
};


export const getTopicsByTopicId = async (topic_id) => {
    let API = `topics/${topic_id}`;

    return await baseGetRequest(API, 'GET');
};


export const deleteTopic = async (topic_id) => {
    let API = `topics`;

    if (topic_id) {
        API += `?topic_id=${topic_id}`
    }

    return await baseGetRequest(API, 'DELETE');
};


/** DocStructureGroups APIs **/

export const createDocStructureGroup = async (customer, postObject) => {
    return await baseGetRequest(`${customer}/doc-structure-groups`, 'POST', postObject);
};


export const getDocStructureGroups = async (customer, topicId) => {
    let API = `${customer}/doc-structure-groups`;

    if (topicId) {
        API += `?topic_id=${topicId}`
    }

    return await baseGetRequest(API, 'GET');
};


export const getDocStructureGroupsByGroupId = async (customer, group_id) => {
    let API = `${customer}/doc-structure-groups/${group_id}`;

    return await baseGetRequest(API, 'GET');
};


export const deleteDocStructureGroup = async (customer, group_id) => {
    let API = `${customer}/doc-structure-groups`;

    if (group_id) {
        API += `?group_id=${group_id}`
    }

    return await baseGetRequest(API, 'DELETE');
};


/** CMS integration APIs **/

export const getCmsCredsByCustomer = async (customer) => {
    return await baseGetRequest(`${customer}/cms/creds`);
};


export const createCmsCreds = async (customer, postObject) => {
    return await baseGetRequest(`${customer}/cms/creds`, 'POST', postObject);
};


export const deleteCmsCreds = async (customer) => {
    return await baseGetRequest(`${customer}/cms/creds`, 'DELETE');
};


export const getPublishingRulesByCustomer = async (customer) => {
    return await baseGetRequest(`${customer}/cms/publishing-rules`);
};


export const createPublishingRule = async (customer, postObject) => {
    return await baseGetRequest(`${customer}/cms/publishing-rules`, 'POST', postObject);
};


export const updatePublishingRule = async (customer, rule_name, postObject) => {
    return await baseGetRequest(`${customer}/cms/publishing-rules/${rule_name}`, 'PUT', postObject);
};


export const deletePublishingRule = async (customer, rule_name) => {
    return await baseGetRequest(`${customer}/cms/publishing-rules/${rule_name}`, 'DELETE');
};


/** Analytics APIs **/

export const getTopMessages = async (customer, component, topicId, language) => {
    return await baseGetRequest(`${customer}/analytics/top-messages?component=${component}&language=${language}&topic_id=${topicId}`);
};
