import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
const theme = createMuiTheme({
    breakpoints: {
      values: {
          xs: 0,
          sm: 768,
          md: 1280,
          lg: 1440,
          xl: 1920
      }
    },
    mixins: {
      toolbar: {
          '@media (min-width:600px)': {
              minHeight: 96
          }
      }
    },
    palette: {
        text: {
            primary: "#2b2e4a",
        },
        primary: {
            main: '#FF8057',
        },
        secondary: {
            main: '#7494ea',
        },
    },
    typography: {
        fontFamily: [
            'Nunito Sans',
            '"Helvetica Neue"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        body1: {
            fontWeight: 600,
            fontSize: '0.875rem', //14px at 16base
            color: '#3F4658',
        },
        h6: {
            fontWeight: 700,
            fontSize: '1.125rem', //18px at 16base
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: '1rem', //16px at 16base
        }
    },
    shape: {
        borderRadius: 12
    },
    shadows: [
        "none",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 20px  rgba(95, 131, 165, 0.10)",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)",
        "0px 10px 40px  rgba(95, 131, 165, 0.19)"
    ],
});

function withRoot(Component) {
    function WithRoot(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
