import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {Link, useLocation} from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        paddingTop: theme.spacing(1),
        flexDirection: 'row',
        alignItems: 'center',
    },
    breadcrumbsContainer: {
        color: 'white'
    },
    link: {
        textDecoration: "none",
        color: 'white',
        fontWeight: 700,
        fontSize: '1.125rem', //18px at 16base
    }
}));


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function HoopsBreadCrumbs() {
    const classes = useStyles();
    const location = useLocation();
    const query = useQuery();
    let assets = query.get('assets');

    if (assets) {
        assets = assets.replace(',', ', ')
    }

    const topicId = useSelector(state => state.selection.topicId);
    const topicsArr = useSelector(state => state.selection.topicsArr);

    function capitalize(targetStr) {
        return targetStr.charAt(0).toUpperCase() + targetStr.slice(1);
    }

    const selectedTopic = topicsArr.find((topic) => topic['topic_id'] === topicId);

    const topicFamily = capitalize(selectedTopic.family);
    const topicGroup = capitalize(selectedTopic.group);
    const name = capitalize(selectedTopic['topic_name']);

    const pathnames = location.pathname.split('/').filter(x => x);
    const dynamicCrumbs = pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        if (value === 'topics') {
            return null
        }

        return last ? (
            <Typography variant="h6" color="inherit" key={to}>
                {capitalize(value) + ` ${assets || ''}`}
            </Typography>
        ) : (
            <Typography className={classes.link} to={to} key={to}>
                {capitalize(value)}
            </Typography>
        );
    });

    return (
        <div className={classes.root}>
            <Breadcrumbs className={classes.breadcrumbsContainer} separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                {topicFamily !== 'Finance' &&
                <>
                    <Link className={classes.link} to='/topics'>
                        {topicFamily}
                    </Link>
                    <Link className={classes.link} to='/topics'>
                        {topicGroup}
                    </Link>
                </>
                }
                <Link className={classes.link} to='/dashboard'>
                    {name}
                </Link>
                {dynamicCrumbs}
            </Breadcrumbs>
        </div>
    );
}
