import React, {Suspense, useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {useSelector, useDispatch} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import HomeDrawer from "./HomeDrawer"
import HomeAppBar from "./HomeAppBar"
import {getComponents} from "../../tools/api";
import {setComponentsArr, showSnackbar} from "../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh'
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '50vh',

    },
    dashboardBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '60vh',
        position: 'absolute',
        width: '100%',
        borderRadius: '0 0 0 25px',
        boxShadow: theme.shadows[1],
        zIndex: '-1'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function Home(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const topicId = useSelector(state => state.selection.topicId);
    const theme = useTheme();
    const isBigScreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
    const isLaptop = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});

    const [open, setOpen] = useState(isBigScreen);
    const [bg, setBackground] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (isLaptop) {
            import('../../resources/new_bg.svg').then((dashboardBg) => {
                    setBackground(`url(${dashboardBg.default})`)
                });
        } else {
            import('../../resources/ipad_bg.svg').then((dashboardBg) => {
                setBackground(`url(${dashboardBg.default})`)
            });
        }

        getComponents(topicId).then(res => {
            dispatch(setComponentsArr(res['result']['component_list'].sort((a, b) => a['component_name'] > b['component_name'])));
        }).catch(e => {
            dispatch(showSnackbar(e.message));
        });
        setSelectedIndex(props.selectedIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.view, topicId]);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <HomeAppBar open={open} onLogout={props.onLogout} isBigScreen={isBigScreen}/>
            <HomeDrawer isAdmin={props.isAdmin} open={open} handleSetOpen={() => setOpen(!open)} selectedIndex={selectedIndex}/>
            <main className={classes.content}>
                <div className={classes.dashboardBG} style={{backgroundImage: bg}}/>
                <div className={classes.appBarSpacer}/>
                <Suspense fallback={
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                }>
                    {props.view}
                </Suspense>
            </main>
        </div>
    );
}

Home.propTypes = {
    view: PropTypes.node.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onLogout: PropTypes.func.isRequired
};
