const dev = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_LAF6phgAJ",
        APP_CLIENT_ID: "11e53r8kgfi44gvlhuac1uq6sn",
        IDENTITY_POOL_ID: "us-east-1:13fa56a6-dbc4-4775-9c65-3ce3828c54a6"
    }
};

const prod = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_LAF6phgAJ",
        APP_CLIENT_ID: "11e53r8kgfi44gvlhuac1uq6sn",
        IDENTITY_POOL_ID: "us-east-1:13fa56a6-dbc4-4775-9c65-3ce3828c54a6"
    }
};

// Default to dev if not set
const awsConfig = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...awsConfig
};
