import React, {useEffect, Fragment} from 'react';
import Routes from './tools/Routes';
import {useSelector, useDispatch} from 'react-redux'
import {LOGIN, LOGOUT, SET_CUR_CUSTOMER} from './actions'
import {withRouter} from "react-router-dom";
import withRoot from "./withRoot";
import SnackBar from "./components/SnackBar";

import Auth from '@aws-amplify/auth';


const App = props => {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);
    const userGroups = useSelector(state => state.user.userGroups);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const isAuthenticating = useSelector(state => state.user.isAuthenticating);
    const admin = useSelector(state => state.user.admin);
    const customer = useSelector(state => state.selection.customer);

    function userHasAuthenticated(authenticated, user, userGroups, userPhoto, admin) {
        const target_customer = (userGroups.includes(customer)) ? customer : userGroups[0];

        dispatch({
            type: SET_CUR_CUSTOMER,
            payload: target_customer
        });
        dispatch({
            type: LOGIN,
            payload: {
                isAuthenticated: authenticated,
                user: user,
                userGroups: userGroups,
                isAuthenticating: false,
                userPhoto: userPhoto,
                admin: admin
            }
        });
    }

    async function handleLogout() {
        await Auth.signOut();

        dispatch({type: LOGOUT});
        props.history.push("/login");
    }

    useEffect(() => {
        try {
            Auth.currentSession()
                .then(data => {
                    let userGroups = data.accessToken.payload["cognito:groups"] || [];
                    let admin = false;
                    // TODO: Remove this
                    const adminIndex = userGroups.findIndex(g => g === 'hoopsai-admins');

                    if (adminIndex !== -1) {
                        admin = true;
                        userGroups.splice(adminIndex, 1);
                    }

                    const user = data.idToken.payload['cognito:username'];
                    const userPicture = data.idToken.payload.picture;
                    userHasAuthenticated(true, user, userGroups, userPicture, admin);
                })
                .catch(err => console.log(err));
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        dispatch({type: LOGIN, payload: {admin: false, user: null, userGroups: [], isAuthenticating: false}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appProps = {
        isAuthenticated: isAuthenticated,
        userHasAuthenticated: userHasAuthenticated,
        onLogout: handleLogout,
        user: user,
        userGroups: userGroups,
        history: props.history,
        isAdmin: admin,
    };

    return (
        !isAuthenticating &&
        <Fragment>
            <SnackBar/>
            <Routes appProps={appProps}/>
        </Fragment>
    )
};

export default withRoot(withRouter(App));
