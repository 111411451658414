import {SET_COMPONENTS_ARR} from '../actions'

const initialState = {
    componentsArr: [],
};

const componentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPONENTS_ARR:
            return {...state, componentsArr: action.payload};
        default:
            return state;
    }
};

export default componentsReducer
