import React from "react";
import PropTypes from "prop-types";

import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";


export default function PopperMenu(props) {
    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Popper className={props.class} id={id} open={open} anchorEl={props.anchorEl} transition disablePortal>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                    <Paper elevation={2}>
                        <ClickAwayListener onClickAway={props.onClickAwayListener}>
                            <MenuList>
                                {props.children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

PopperMenu.propTypes = {
    class: PropTypes.func,
    anchorEl: PropTypes.instanceOf(Element),
    onClickAwayListener: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
