import React, { lazy } from 'react';
import {Route, Switch} from "react-router-dom";

import AuthenticatedRoute from "../components/AuthenticatedRoute";
import AdminRoute from "../components/AdminRoute";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";

import Home from "../scenes/Home/Home"
import NotFound from "../scenes/NotFound/index"
import Forbidden from "../components/Forbidden";
import Login from "../scenes/Login/index"

const AsyncTopics = lazy(() => import('../scenes/Topics/Topics'));
const AsyncDashboard = lazy(() => import('../scenes/Dashboard/Dashboard'));
const AsyncEditor = lazy(() => import("../scenes/Editor/Editor"));
const AsyncFinanceEditor = lazy(() => import("../scenes/FinanceEditor/FinanceEditor"));
const AsyncMessagesView = lazy(() => import("../scenes/Messages/MessagesView"));
const AsyncUnverifiedView = lazy(() => import("../scenes/Unverified/UnverifiedView"));
const AsyncDocStructuresView = lazy(() => import("../scenes/DocStructures/DocStructuresView"));
const AsyncCmsIntegrationView = lazy(() => import("../scenes/CmsIntegration/CmsIntegrationView"));
const AsyncComponents = lazy(() => import("../scenes/Components/Components"));
const AsyncDocStructureGroups = lazy(() => import("../scenes/DocStructureGroups/DocStructureGroups"));
const AsyncDataPointsView = lazy(() => import("../scenes/DataPoints/DataPointsView"));
const AsyncCombinationsView = lazy(() => import("../scenes/Combinations/CombinationsView"));
const AsyncAnalyticsView = lazy(() => import("../scenes/AnalyticsPanel/AnalyticsView"));
const AsyncTestingView = lazy(() => import("../scenes/TestingPanel/TestingView"));
const AsyncAdminPanel = lazy(() => import("../scenes/AdminPanel/AdminPanel"));


export default function Routes({appProps}) {
    return (
        <Switch>
            <AuthenticatedRoute path="/" exact component={Home} appProps={{...appProps, ...{'view': <AsyncDashboard/>, 'selectedIndex': 0}}}/>
            <AuthenticatedRoute path="/dashboard" exact component={Home} appProps={{...appProps, ...{'view': <AsyncDashboard/>, 'selectedIndex': 0}}}/>
            <AuthenticatedRoute path="/editor/:gameType/:gameId" exact component={Home} appProps={{...appProps, ...{'view': <AsyncEditor/>, 'selectedIndex': 0}}}/>
            <AuthenticatedRoute path="/finance-editor" exact component={Home} appProps={{...appProps, ...{'view': <AsyncFinanceEditor/>, 'selectedIndex': 0}}}/>
            <AuthenticatedRoute path="/topics" exact component={Home} appProps={{...appProps, ...{'view': <AsyncTopics/>, 'selectedIndex': 1}}}/>
            <AuthenticatedRoute path="/messages" exact component={Home} appProps={{...appProps, ...{'view': <AsyncMessagesView/>, 'selectedIndex': 2}}}/>
            <AuthenticatedRoute path="/unverified" exact component={Home} appProps={{...appProps, ...{'view': <AsyncUnverifiedView/>, 'selectedIndex': 3}}}/>
            <AuthenticatedRoute path="/doc-structures" exact component={Home} appProps={{...appProps, ...{'view': <AsyncDocStructuresView/>, 'selectedIndex': 4}}}/>
            <AuthenticatedRoute path="/doc-structure-groups" exact component={Home} appProps={{...appProps, ...{'view': <AsyncDocStructureGroups/>, 'selectedIndex': 5}}}/>
            <AuthenticatedRoute path="/cms-integration" exact component={Home} appProps={{...appProps, ...{'view': <AsyncCmsIntegrationView/>, 'selectedIndex': 6}}}/>
            <AuthenticatedRoute path="/forbidden" exact component={Forbidden} appProps={appProps}/>

            <AdminRoute path="/components" exact component={Home} appProps={{...appProps, ...{'view': <AsyncComponents/>, 'selectedIndex': 7}}}/>
            <AdminRoute path="/data-points" exact component={Home} appProps={{...appProps, ...{'view': <AsyncDataPointsView/>, 'selectedIndex': 8}}}/>
            <AdminRoute path="/combinations" exact component={Home} appProps={{...appProps, ...{'view': <AsyncCombinationsView/>, 'selectedIndex': 9}}}/>
            <AuthenticatedRoute path="/analytics" exact component={Home} appProps={{...appProps, ...{'view': <AsyncAnalyticsView/>, 'selectedIndex': 10}}}/>
            <AdminRoute path="/testing" exact component={Home} appProps={{...appProps, ...{'view': <AsyncTestingView/>, 'selectedIndex': 11}}}/>
            <AdminRoute path="/admin" exact component={Home} appProps={{...appProps, ...{'view': <AsyncAdminPanel/>, 'selectedIndex': 12}}}/>

            <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps}/>
            <UnauthenticatedRoute path="/signup" exact component={Login} appProps={appProps}/>

            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    );
}
